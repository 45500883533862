<template>
  <div :class="!isExpanded && value?.length > limit ? truncateClass : ''">
    <StyledText :em-class="emClass" :link-class="linkClass" :value="value" />
  </div>

  <v-btn
    v-if="!isExpanded && value?.length > limit"
    :icon="mdiPlus"
    class="ma-2"
    size="x-small"
    rounded="0"
    variant="outlined"
    @click="isExpanded = true"
  />
</template>

<script setup>
import { ref } from 'vue'
import { mdiPlus } from '@mdi/js'

defineProps({
  emClass: {
    default: undefined,
    type: String
  },
  limit: {
    default: 200,
    type: Number
  },
  linkClass: {
    default: undefined,
    type: String
  },
  truncateClass: {
    default: 'text-truncate-3',
    type: String
  },
  value: {
    default: undefined,
    type: String
  }
})

const isExpanded = ref(false)
</script>
